<template>
    <content-view>
        <vue-progress-bar/>
        <div class="education__course-add" v-if="isLoaded">
            <course-header
                :is-sending="isSending"
                :return-route="backRouteLink"
                :btn-handler="onSubmit"
                btn-title="Сохранить"
            />
            <ValidationObserver tag="div" class="course-add__body" @submit.prevent="onSubmit" ref="observer">
                <div class="course-add__card">
                    <ValidationProvider v-if="!preview && !form.image" for="id_image" tag="label" vid="image" class="course-add__image" v-slot="provider">
                        <icon width="58" height="46">
                            <path d="M57 0H1C0.448 0 0 0.447 0 1V45C0 45.553 0.448 46 1 46H57C57.552 46 58 45.553 58 45V1C58 0.447 57.552 0 57 0ZM56 44H2V2H56V44Z" fill="#989CAE"/>
                            <path d="M16.0002 22.138C19.0712 22.138 21.5692 19.64 21.5692 16.57C21.5692 13.498 19.0712 11 16.0002 11C12.9292 11 10.4312 13.498 10.4312 16.569C10.4312 19.64 12.9292 22.138 16.0002 22.138ZM16.0002 13C17.9682 13 19.5692 14.602 19.5692 16.569C19.5692 18.536 17.9682 20.138 16.0002 20.138C14.0322 20.138 12.4312 18.537 12.4312 16.57C12.4312 14.603 14.0322 13 16.0002 13Z" fill="#989CAE"/>
                            <path d="M7.00004 39.9998C7.23404 39.9998 7.47004 39.9178 7.66004 39.7508L23.973 25.3888L34.275 35.6898C34.666 36.0808 35.298 36.0808 35.689 35.6898C36.08 35.2988 36.08 34.6668 35.689 34.2758L30.882 29.4688L40.063 19.4148L51.324 29.7378C51.731 30.1108 52.364 30.0828 52.737 29.6758C53.11 29.2688 53.083 28.6358 52.675 28.2628L40.675 17.2628C40.479 17.0838 40.218 16.9948 39.955 17.0008C39.69 17.0128 39.44 17.1298 39.261 17.3258L29.467 28.0528L24.724 23.3098C24.35 22.9368 23.752 22.9178 23.356 23.2658L6.33904 38.2488C5.92404 38.6138 5.88404 39.2458 6.24904 39.6608C6.44704 39.8858 6.72304 39.9998 7.00004 39.9998Z" fill="#989CAE"/>
                        </icon>
                        <span class="v-link course-add__image-link">Загрузить изображение</span>
                    </ValidationProvider>

                    <label for="id_image" v-else class="course-add__image-wrapper">
                        <img :src="preview || form.image" class="course-add__image course-add__image--preview" alt="">
                        <label for="id_image" class="v-btn v-btn--white course-add__image-btn">Изменить</label>
                    </label>


                    <ValidationProvider tag="div" class="course-add__status" vid="status" v-slot="{errors}" :rules="`required`">
                        <label for="id_status" class="course-add__status-label">Статус</label>
                        <v-select2
                            id="id_status"
                            :options="statusList"
                            label="name"
                            v-model="form.status"
                            :reduce="status => status.id"
                            :clearable="false"
                            :searchable="false"
                        ></v-select2>
                        <span class="v-error animated fadeInDown" v-if="errors.length">
                            {{errors[0]}}
                        </span>
                    </ValidationProvider>
                </div>
                <div class="course-add__content js-without-double-block">
<!--                    <ValidationProvider class="mb-20" tag="div" vid="name" v-slot="{errors}" :rules="`required|max_length:255`">
                        <input v-model="form.name" maxlength="255" class="course-add__content-title v-input v-input&#45;&#45;noborder" type="text" placeholder="Название курса...">
                        <span class="v-error animated fadeInDown" v-if="errors.length">
                            {{errors[0]}}
                        </span>
                    </ValidationProvider>-->
                    <ValidationProvider tag="div" vid="text" v-slot="{errors}" :rules="`required`">
                        <new-editor
                            v-model="form.text"
                            :data="form.text"
                            :top-title="form.name"
                            top-placeholder="Название курса"
                            bottom-placeholder="Описание курса1"
                            id="id_text"
                            no-vidget
                            @needSubmitBeforeAttach="onNeedSubmitBeforeAttach"
                        />
                        <span class="v-error animated fadeInDown" id="error_text" v-if="errors.length">
                            {{errors[0]}}
                        </span>
                    </ValidationProvider>
                    <input type="file" id="id_image" name="image" accept="image/*" ref="image" @change="handleFileUpload" hidden>
                </div>
            </ValidationObserver>
        </div>
    </content-view>
</template>

<script>

import {mapState, mapMutations} from "vuex";
import session from '@/api/session'
import NewEditor from '../../../components/editor/newEditor'

export default {
    name: "CourseChange",
    components: {
        NewEditor,
        CourseHeader : () => import('@/components/course/CourseHeader'),
        ContentView : () => import('@/views/menu/ContentView'),
        Icon : () => import('@/components/icon/Icon'),
    },
    props: {
        course_id: {
            type: String,
            default: null
        },
    },
    computed: {
        ...mapState('default_data', [
            'current_user', 'newCourseId'
        ]),
        backRouteLink() {
            return this.courseId ? `/courses/${this.courseId}` : `/courses`
        }
    },
    data() {
        return {
            isSending: false,
            statusList: [],
            preview: null,
            form: {
                status: 0,
                image: null,
                text: '',
                name: ''
            },
            headerTitle: this.course_id ? 'Редактирование курса':'Добавление курса',
            courseId: null,
            isLoaded: false,
            isNewImage: false,
            submittingNewCourse: false
        }
    },
    async beforeCreate () {
        try {
            const request = session.get(`/api/v1/course/access_change/`);
            const response = await request;
        } catch (error) {
            this.$router.push('/403');
        }
    },
    async created() {
        const request = await session.get('/api/v1/course/default_data/');
        this.statusList = request.data.status;
        this.form.status = +this.$route.params.status;
        this.courseId = this.$route.params.course_id || null;
        if (this.courseId) {
            await this.loadCourseData();
        }
        this.isLoaded = true;

    },
    methods: {
        ...mapMutations('default_data', ['setNewCourseId']),
        onNeedSubmitBeforeAttach(cb) {
            if (!this.newCourseId) {
                this.submittingNewCourse = true
                this.onSubmit(cb)
            }
        },
        async onSubmit(cb) {
            this.isSending = true;
            this.$Progress.start();

            let isValid = await this.$refs.observer.validate();

            if (isValid) {
                let { form } = this;
                let data = {
                    ...form,
                    text: form.text.html
                };

                let content = form.text.json.content;
                let contentTitle = content.find(item => item.type === 'title').content;

                if ((!content.length || !contentTitle)) {
                    if (this.submittingNewCourse) {
                        data.name = 'Без названия';
                    } else {
                        this.$refs.observer.setErrors({
                            text: ['Введите название курса']
                        });
                        this.$nextTick(() => {
                            this.$scrollTo('#error_text', {offset: 300});
                        })
                        this.isSending = false;
                        return false;
                    }
                } else {
                    data.name = contentTitle.map((variable) => variable.text).join('');
                }

                let config = {};

                if (this.isNewImage) {
                    let formData = new FormData();
                    config['headers'] = {
                        'Content-Type': 'multipart/form-data'
                    };
                    for (let [key, value] of Object.entries(data)) {
                        if (Array.isArray(value) && value.length) {
                            for (let item of value) {
                                formData.append(`${key}[]`, item);
                            }
                            continue;
                        }
                        if (!!value || value === 0) {
                            formData.append(key, value);
                        }
                        data = formData;
                    }
                } else {
                    delete data.image;
                }

                if (this.courseId) {
                    await session.put(
                        `/api/v1/course/${this.courseId}/`,
                        data,
                        config
                    )
                    .then((response) => {
                        this.$router.push(`/courses/${this.courseId}`);
                    })
                    .catch((error) => {
                        if (error.response) {
                            this.$refs.observer.setErrors({
                                text: [`${error.response.data.name}`]
                            });
                        }
                    });
                } else {
                    await session.post(`/api/v1/course/`, data, config)
                    .then((response) => {
                        if (this.submittingNewCourse) {
                            this.setNewCourseId(response.data.id)
                            this.submittingNewCourse = false
                            cb()
                        } else {
                            this.$router.push(`/courses/${response.data.id}`)
                        }
                    })
                    .catch((error) => {
                        if (error.response) {
                            this.$refs.observer.setErrors({
                                text: [`${error.response.data.name}`]
                            });
                        }
                    });
                }
            }

            this.$Progress.finish();
            this.isSending = false;
        },
        handleFileUpload() {
            this.form.image = this.$refs.image.files[0];
            let reader = new FileReader();
            reader.addEventListener("load", function () {
                const CropperModal = () => import(`@/components/modals/CropperModal`);
                this.$modal.show(CropperModal, {
                        image: reader.result
                    }, {
                        name: 'CropperModal',
                        adaptive: true,
                        maxWidth: 740,
                        width: '100%',
                        height: 'auto',
                        clickToClose: false,
                        scrollable: true,
                        pivotY: 0,
                        styles: 'margin-top: 8px; margin-bottom: 8px;'
                    }, {
                        'onSave': data => {
                            this.form.x = data.x;
                            this.form.y = data.y;
                            this.form.width = data.width;
                            this.form.height = data.height;
                            this.isNewImage = true;
                        },
                        'setPreview': preview => {
                            this.preview = preview;
                        }
                    }
                )
            }.bind(this), false);

            if (this.form.image) {
                reader.readAsDataURL(this.form.image);
            }
        },
        errorHandler(err) {
            console.error(err);
            if (err.response) {
                this.$refs.observer.setErrors(err.response.data);
            } else {
                this.$swal({
                    title: 'Ошибка!',
                    text: 'При обработке запроса произошла ошибка на сервере',
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 1400,
                });
            }
        },
        async loadCourseData() {
            let response;
            try {
                const request = session.get(`/api/v1/course/${this.courseId}/`);
                response = await request;
                let data = response.data
                this.form = {
                    ...data,
                    status: data.status.id
                };
            } catch (err) {
                console.error('err', err)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "#sass/v-style";

.course-add {
    &__body {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 1024px;
        margin: 100px auto 0;
        height: 100%;
        @include below(1000px) {
            margin: 0;
            padding: 0;
        }
    }
    &__card {
        padding: 20px;
        display: flex;
        background: #FFFFFF;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.12);
        border-radius: 16px;
    }
    &__content {
        margin-top: 50px;
    }
    &__image {
        cursor: pointer;
        height: 200px;
        width: 200px;
        background: #FFF;
        border: 1px dashed #D5DBE4;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0;
        object-fit: cover;
        &--preview {
            border: none;
        }
        &-link {
            margin-top: 20px;
        }
        &-wrapper {
            position: relative;
            overflow: hidden;
            cursor: pointer;
            margin-bottom: 0;
            &:hover {
                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border-radius: 8px;
                    background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));

                }
                .course-add__image-btn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
        &-btn {
            display: none;
            z-index: 100;
            @include absolute-centered;
        }
    }
    &__status {
        max-width: 250px;
        width: 100%;
        margin-left: 40px;
    }
}
</style>
